<template>
  <div class="services_section">

    <!-- <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ checkBn ?  getParentName($route.query.service_type, parentId).name_bn : getParentName($route.query.service_type, parentId).name }}</h2>
      <img src="../../../assets/images/portal/cover-img.jpg" alt="" />
    </div> -->
    <!-- <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item"><router-link to="/portal/all-services">{{ $t('portal.all_service') }}</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('portal.service_details') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div> -->
    <b-overlay :show="loading">
        <div class="service_details_wrapper">
        <b-container>
            <b-row>
            <b-col>
                <div class="service_details">
                <h3 class="service_name">{{ getProductName($route.query.service_id) }}</h3>
                <div class="card card_details">
                    <div class="card-header">
                    <h5 class="title">{{ $t('portal.product_description') }}</h5>
                    </div>
                    <div class="card-body">
                    <b-row>
                        <b-col sm="12">
                        <div class="serve_description">
                            <b-table-simple style="color:black;" bordered>
                                <b-tr>
                                    <b-td> {{ $t('damReport.commodity_group') }} </b-td>
                                    <b-td> {{ $t('damReport.measurement') }} </b-td>
                                    <b-td> {{ $t('damReport.retail_price_max_min') }} </b-td>
                                    <b-td> {{ $t('damReport.measurement') }} </b-td>
                                    <b-td> {{ $t('damReport.wholesale_price_max_min') }} </b-td>
                                </b-tr>
                                <b-tr v-if="service.length > 0">
                                    <b-td> {{ getGroupName(service[0].com_grp_id) }} </b-td>
                                    <b-td> {{ getUnitName(service[0].unit_retail) }} </b-td>
                                    <b-td> {{  $n(parseFloat(service[0].r_lowestPrice)) }} - {{  $n(parseFloat(service[0].r_highestPrice)) }} </b-td>
                                    <b-td> {{ getUnitName(service[0].unit_wholesale) }}  </b-td>
                                    <b-td> {{  $n(parseFloat(service[0].w_lowestPrice)) }} - {{  $n(parseFloat(service[0].w_highestPrice)) }}  </b-td>
                                </b-tr>
                                <b-tr v-else>
                                    <b-td colspan="5" style="text-align: center"> {{ $t('globalTrans.noDataFound') }} </b-td>
                                </b-tr>
                            </b-table-simple>
                        </div>
                        </b-col>
                    </b-row>
                    </div>
                </div>
                </div>
            </b-col>
            </b-row>
        </b-container>
        </div>
    </b-overlay>
  </div>
</template>
<script>
import RestApi, { commonServiceBaseUrl, agriMarketingServiceBaseUrl } from '../../../config/api_config'
import Common from '@/mixins/portal/common'

export default {
  mixins: [Common],
  components: {
  },
  data () {
    return {
      loading: false,
      parentId: '',
      service: {},
      hasIncentiveGrantCircular: false,
      hasIncentiveSubsidyCircular: false,
      hasPubCircular: false,
      hasResearchCircular: false,
      hasLicenseCircularNotice: false,
      commonServiceBaseUrl: commonServiceBaseUrl
    }
  },
  created () {
    // this.loadData()
    this.loadData()
  },
  watch: {
        '$route.query.service_id': 'loadData'
    },
    methods: {
        async loadData () {
        if (this.$route.query.service_id) {
            this.loading = true
            const result = await RestApi.getData(agriMarketingServiceBaseUrl, '/product-show', { id: this.$route.query.service_id })
            this.loading = false
            if (result.success) {
                this.service = result.data
            } else {
                this.service = []
            }
        } else {
            this.$router.push('/portal/home')
        }
        },
        getGroupName (groupID) {
            if (groupID) {
                const obj = this.$store.state.Portal.agriObj.commodityGroupList.find(el => el.value === parseInt(groupID))
                if (obj !== 'undefinded') {
                    return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
                }
            } else {
                return ''
            }
        },
        getProductName (groupID) {
            if (groupID) {
                const obj = this.$store.state.Portal.agriObj.commodityNameList.find(el => el.value === parseInt(groupID))
                if (obj !== 'undefinded') {
                    return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
                }
            } else {
                return ''
            }
        },
        getUnitName (groupID) {
            if (groupID) {
                const obj = this.$store.state.Portal.agriObj.measurementUnitList.find(el => el.value === parseInt(groupID))
                if (obj !== 'undefinded') {
                    return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
                }
            } else {
                return ''
            }
        }
    }
}
</script>
